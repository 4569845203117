/**
 * @desc Menu functionality for e-Editions.
 */
(function(window, document, $, __tnt) {
    var me = __tnt.eedition || (__tnt.eedition = {});

    var mainMenu = $("#eedition-mainmenu"),
        sidebarMenu = $(".eedition-sidebar"),
        bottombarMenu = $('.eedition-bottombar'),
        menus = [mainMenu, sidebarMenu, bottombarMenu],
        eeData = $('*[data-eedition]').data('eedition'),
        pageUuid = $('*[data-eedition]').data('eedition-uuid');

    me.menu = {

        // Initialize menus
        init: function() {

            // Initial menu update
            me.menu.update();

            // Main menu icon
            $("#eedition-menu-icon").click(function() {
                me.menu.main();
            });
            
            // View display dropdown 
            $('.eedition-view-option').on('click', function(event) {
                event.preventDefault();
                var showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                setTimeout(function () {
                    if (event.target.getAttribute("data-view") == 'text') {
                        Cookies.set('__tnt_eedition_mode', 'text', { expires: 365, path: '/' });
                    } else {
                        Cookies.set('__tnt_eedition_mode', 'image', { expires: 365, path: '/' });
                    }
                    me.navigation.navigate($('*[data-eedition]').data('eedition-uuid'), true, showThumbs);
                }, 200);
            });

            // Zoom buttons
            $('.eedition-zoom').on('click', function() {
                if ($(this)[0].dataset.disabled != 'true') {
                    me.image.zoom($(this).data('direction'));
                }
            });

            // Clip button
            if (me.browser.isIe()) {
                $('#eedition-clip').hide();
            }
            $('#eedition-clip').on('click', function() {
                me.clip.init();
            });

            // Navigation arrows switch
            $('.eedition-settings-switch').on('click', function() {
                var showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                setTimeout(function () {
                    $('.eedition-settings-switch input').prop('disabled', true);
                    if ($('.eedition-settings-switch input').attr('checked') == 'checked') {
                        Cookies.set('__tnt_eedition_nav_arrows', 0, { expires: 365, path: '/' });
                    } else {
                        Cookies.set('__tnt_eedition_nav_arrows', 1, { expires: 365, path: '/' });
                    }
                    me.navigation.navigate($('*[data-eedition]').data('eedition-uuid'), true, showThumbs);
                }, 200);
            });

            // Sidebar menu icon
            $(".sidebar-icon").click(function() {
                me.menu.side($(this).data("menu"));
            });
            
            // Sidebar sign in
            if (__tnt.user.loggedIn) {
                document.getElementById('user-profile-login').classList.add('hidden');
                document.getElementById('user-profile-logout').classList.remove('hidden');
            }

            // Bottom menu icon
            $(".eedition-bottombar-btn").click(function() {
                me.menu.bottom();
                me.resize();
                if (me.utilities.viewMode(pageUuid) == 'image' && me.iscroll.segments) me.iscroll.segments.refresh();
                if (me.utilities.viewMode(pageUuid) == 'text' && me.iscroll.map) {
                    me.iscroll.map.refresh();
                    me.imageMap.init();
                }
            });

            // Download current page
            $('.page-download').click(function() {
                me.utilities.gtmDownloadEvent('page download', $(this).text(), $(this).attr('href'));
                return true;
            });

            // Download full eEdition
            $('.edition-download').click(function() {
                me.utilities.gtmDownloadEvent('full download', $(this).text(), $(this).attr('href'));
                me.menu.download(this);
            });

            // Retry download
            $('#retry-download-edition-btn').click(function() {
                $('.loader').hide();
                $('.sidebar-downloads-error').hide();
                $('.sidebar-downloads-list').show();
            });

            // Content blocker
            $("#eedition-blocker").click(function() {
                me.menu.reset();
            });

            // Search
            $('.sidebar-icon.search').click(function() {
                me.menu.reset();
            });

            // Bottom menu navigation/update/preload
            $('.eedition-thumbnail-link, .eedition-section-link').on('tap', function() {
                var sectionLink = false;
                if (this.className == 'eedition-section-link') sectionLink = true; 
                me.navigation.navigate($(this).data('eedition-uuid'), sectionLink, true);
            });

            // Logout logic
            $('#user-profile-logout').click(function() {
                $('#tncms-logout-form').submit();
            });

            // Text size popover
            $('.eedition-text-size').click(function () {
                me.menu.textSize(this);
            });

            // Share page
            $('#eedition-share').click(function() {
                me.menu.share(this);
            });

            // Downloads
            $('#eedition-download').click(function() {
                me.menu.side('downloads');
            });

            // Editions
            $('#eedition-editions').click(function() {
                me.menu.side('editions');
            });

            // Tutorial
            $('#help-overlay-menu').click(function(){
                me.intro.init(true);
            });
        },

        // Main menu
        main: function() {
            if (mainMenu.hasClass("active") || sidebarMenu.hasClass("active")) {
                me.menu.reset();
            } else {
                if (bottombarMenu.hasClass("active")) me.menu.reset();
                $([mainMenu, sidebarMenu]).each(function() {
                    $(this).addClass("active");
                });
            }
        },

        // Side menu
        side: function(selection) {
            if (!mainMenu.hasClass(selection) || !sidebarMenu.hasClass(selection)) {
                $([mainMenu, sidebarMenu]).each(function() {
                    $(this).removeClass("editions downloads publications settings").addClass(selection);
                    if (!$(this).hasClass('active')) {
                        $(this).addClass('active');
                    }
                });
            } else {
                $([mainMenu, sidebarMenu]).each(function() {
                    $(this).removeClass("editions downloads publications settings");
                });
            }
        },

        // Bottom menu
        bottom: function() {
            if (bottombarMenu.hasClass("active")) {
                bottombarMenu.removeClass("active");
            } else {
                bottombarMenu.addClass("active");
            }
        },

        // Full eEdition download
        download: function(selection) {
            var attempts = 0,
                attemptDelay = 500,
                mergedURL = $(selection).data('download').url;
            $('.loader').show();
            $('.sidebar-downloads-list').hide();
            getPDF(mergedURL);
            function getPDF(url) {
                fetch(url).then(function(response) {
                    if (response.status !== 200) {
                        __tnt.log('There was a problem with the download. Status Code: ' + response.status);
                        $('.loader').hide();
                        $('.sidebar-downloads-error').show();
                    } else {
                        response.json().then(function(data) {
                            if (data.status === 'complete') {
                                setTimeout(function(){
                                    $('.loader').hide();
                                    $('.sidebar-downloads-list').show();
                                    window.location.href = data.file;
                                }, 3000);
                            } else if (data.status === 'running') {
                                __tnt.log('File is currently being generated.');
                                attempts++;
                                attemptDelay += 500;
                                if (attempts >= 20) {
                                    __tnt.log('Too many download attempts.');
                                    $('.loader').hide();
                                    $('.sidebar-downloads-error').show();
                                    return;
                                } else {
                                    setTimeout(function(){
                                        getPDF(url);
                                    }, attemptDelay);
                                }
                            }
                        });
                    }
                }).catch(function(e) {
                    __tnt.log('Fetch Error:', e);
                    $('.loader').hide();
                    $('.sidebar-downloads-error').show();
                });
            }
        },

        // Reset menu
        reset: function() {
            $(menus).each(function() {
                $(this).removeClass("active editions downloads publications settings");
                me.resize();
            });
        },

        // Text Size Popover
        textSize: function(button) {
            $(button).popover({
                html: true,
                sanitize: false,
                trigger: 'manual',
                placement: 'bottom',
                content: function () {

                    // Check for textsize cookie
                    var textCookie = Cookies.get('__tnt_eedition_textsize');
                    if (textCookie != null) {
                        textCookie = Cookies.get('__tnt_eedition_textsize')
                    } else {
                        textCookie = 1;
                    }

                    // Reset active selections
                    $('#eedition-text-size-popover #text-size-input').each(function () {
                        $(this).parent().removeClass('active');
                    });

                    // Select input based on textsize cookie
                    $('#text-size-input[data-text-size="'+textCookie+'"]').parent().addClass('active');

                    // Inject form into popover
                    var options = $('#eedition-text-size-popover').html();
                    return options;
                }
             }).popover('toggle');

             // Change textsize based on input selection
             $('.normal, .large, .x-large').on('focus', function() {
                 me.textsize($(this).attr('data-text-size'));
                 $('.eedition-text-size').popover('toggle');
             });
        },

        // Share popover
        share: function(button) {
            $(button).popover({
                html: true,
                sanitize: false,
                trigger: 'manual',
                placement: 'bottom',
                content: function () {
                    var options = $('#eedition-share-popover').html();
                    return options;
                }
            }).popover('toggle');
        },

        // Update menu
        update: function(uuid) {
            var pageSection,
                pageUuid;
            if (uuid) {
                pageUuid = uuid;
            } else {
                pageUuid = $('*[data-eedition]').data('eedition-uuid');
            }
            pageSection = eeData[pageUuid].section;

            // Clear selections
            $('.eedition-thumbnail-link, .eedition-section-link').each(function() {
                $(this).removeClass('selected-section selected-thumbnail');
            });

            // Loop through pages
            $('.eedition-thumbnail-link').each(function() {

                // Highlight selected page
                if ($(this).data('eedition-uuid') == pageUuid) $(this).addClass('selected-thumbnail');

                // Highlight page numbers for the selected section
                if (eeData[$(this).data('eedition-uuid')].section == pageSection) $(this).addClass('selected-section');
            });

            // Loop through sections
            $('.eedition-section-link').each(function() {

                // Highlight sections
                if (eeData[$(this).data('eedition-uuid')].section == pageSection) $(this).addClass('selected-section');
            });

        }
    };

})(window, document, jQuery, __tnt);